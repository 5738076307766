import React, { useState, useEffect } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useContractRead, useSignMessage } from 'wagmi';
import './FaucetPage.css';

const FaucetPage = () => {
  const { address, isConnected } = useAccount();
  const [isTwitterVerified, setIsTwitterVerified] = useState(false);
  const [twitterAuthUrl, setTwitterAuthUrl] = useState('');
  const [twitterVerificationStatus, setTwitterVerificationStatus] = useState('');
  const { signMessageAsync } = useSignMessage();

  const tweetText = `Decentralized AI is coming to Optimism Superchain ecosystem and I'm claiming OUM testnet tokens. probably nothing... @OUMNetwork`;
  const encodedTweetText = encodeURIComponent(tweetText);
  const tweetIntent = `https://twitter.com/intent/tweet?text=${encodedTweetText}`;

  const handleTwitterAuth = async () => {
    try {
      const response = await fetch('/api/twitter/auth-url', {
        credentials: 'same-origin'
      });
      const text = await response.text(); // Get the raw response text
      console.log('Raw response:', text); // Log the raw response
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      try {
        const data = JSON.parse(text); // Try to parse the response as JSON
        window.location.href = data.url; // Redirect to Twitter auth page
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        console.log('Response was not valid JSON:', text);
      }
    } catch (error) {
      console.error('Error starting Twitter auth:', error);
    }
  };

  const contractAddress = '0x6531b80f0Ac6C5fCE2f45dFAC614636a7601Be4f';
  const contractABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_addr",
          "type": "address"
        }
      ],
      "name": "canClaim",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
  ];

  const { data: canClaimResult, isError, isLoading } = useContractRead({
    address: contractAddress,
    abi: contractABI,
    functionName: 'canClaim',
    args: [address],
    enabled: isConnected && !!address,
  });

  const handleClaim = async () => {
    if (!isConnected || !isTwitterVerified) {
      console.log('Wallet not connected or Twitter not verified');
      return;
    }

    if (isLoading) {
      console.log('Checking claim status...');
      return;
    }

    if (isError) {
      console.error('Error checking claim status');
      return;
    }

    if (canClaimResult) {
      try {
        const message = `Claim tokens for address: ${address}`;
        console.log('Requesting user signature...');
        const signature = await signMessageAsync({ message });
        console.log('User signed the message');
        
        const response = await fetch('/api/claim', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ address, signature, message }),
          credentials: 'same-origin'
        });
        
        if (response.ok) {
          console.log('Claim request sent successfully');
          // Handle successful response (e.g., show success message to user)
        } else {
          console.error('Failed to send claim request');
          // Handle error (e.g., show error message to user)
        }
      } catch (error) {
        console.error('Error during claim process:', error);
        // Handle error (e.g., show error message to user)
      }
    } else {
      console.log('Cannot claim at this time');
      // Optionally, show a message to the user explaining why they can't claim
    }
  };

  const buttonStyle = {
    backgroundColor: '#74FB8DFF',
    borderColor: '#74FB8DFF',
    color: '#000000',
    width: '200px',
    minWidth: 'fit-content'
  };

  useEffect(() => {
    // Check if the user was redirected back from Twitter
    const urlParams = new URLSearchParams(window.location.search);
    const oauth_token = urlParams.get('oauth_token');
    const oauth_verifier = urlParams.get('oauth_verifier');

    if (oauth_token && oauth_verifier) {
      // User was redirected back from Twitter, verify their actions
      verifyTwitterActions();
    } 
    // Removed the else block that fetches the Twitter auth URL on mount
  }, []); // Run only once on mount

  useEffect(() => {
    // Check Twitter verification status when address changes
    if (address) {
      checkTwitterVerification();
    }
  }, [address]);

  const checkTwitterVerification = async () => {
    try {
      const response = await fetch('/api/twitter/verify', {
        credentials: 'same-origin'
      });
      const data = await response.json();
      setIsTwitterVerified(data.verified);
      setTwitterVerificationStatus(data.message);
    } catch (error) {
      console.error('Error checking Twitter verification:', error);
      setTwitterVerificationStatus('Error checking verification status');
    }
  };

  const verifyTwitterActions = async () => {
    try {
      const response = await fetch('/api/twitter/verify', {
        credentials: 'same-origin'
      });
      const data = await response.json();
      if (data.verified) {
        setIsTwitterVerified(true);
        setTwitterVerificationStatus('Twitter actions verified successfully');
      } else {
        setIsTwitterVerified(false);
        setTwitterVerificationStatus(data.message || 'Twitter verification failed');
      }
    } catch (error) {
      console.error('Error verifying Twitter actions:', error);
      setTwitterVerificationStatus('Error verifying Twitter actions');
    }
  };

  return (
    <div className="container-fluid page">
      <div className="header mt-5 mb-3">OUM Testnet Faucet</div>
      <div className="subtext2 mb-4">Claim 0.1 sepolia ETH and 100 $OUM per day</div>
      
      <div className="container mb-4">
        <div className="container-content d-flex align-items-start">
          <i className="fab fa-twitter fa-2x mr-3" style={{color: '#74FB8DFF'}}></i>
          <div style={{textAlign: 'left', fontSize: '1rem', color: '#74FB8DFF'}}>
            <strong>Twitter authentication required.</strong>
            <br />
            To prevent spam and abuse of the OUM Testnet Faucet, we require you to authenticate with Twitter and send a tweet.
          </div>
        </div>
      </div>
      
      <div className="container mt-4">
        <div className="container-content d-flex flex-column align-items-center">
          {isConnected ? (
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-stretch w-100">
              <div className="input-group mb-3 mb-md-0 mr-md-3" style={{flex: 1}}>
                <input
                  type="text"
                  className="form-control border-success eth-address-input"
                  placeholder="Enter your ETH address"
                  style={{backgroundColor: '#000000', color: '#74FB8DFF'}}
                  value={address || ''}
                  readOnly
                />
              </div>
              {isTwitterVerified ? (
                <button
                  onClick={handleClaim}
                  className="btn btn-success"
                  style={buttonStyle}
                >
                  Claim
                </button>
              ) : (
                <button
                  onClick={handleTwitterAuth}
                  className="btn btn-success"
                  style={buttonStyle}
                >
                  Authenticate with Twitter
                </button>
              )}
            </div>
          ) : (
            <ConnectButton.Custom>
              {({ openConnectModal }) => (
                <button
                  onClick={openConnectModal}
                  className="btn btn-success"
                  style={buttonStyle}
                >
                  Connect Wallet
                </button>
              )}
            </ConnectButton.Custom>
          )}
        </div>
        <div className="mt-3 text-center" style={{color: '#74FB8DFF', fontSize: '1rem'}}>
          {twitterVerificationStatus || (isConnected 
            ? isTwitterVerified
              ? 'Twitter authenticated. You can now claim your tokens.'
              : 'Wallet connected. Please authenticate with Twitter.'
            : 'Please connect your wallet to get started.')}
        </div>
      </div>
    </div>
  );
};

export default FaucetPage;